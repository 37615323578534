<template>
  <img
    :src="organization?.logoUrl ?? exfluentialLogoSrc"
    alt="Organization logo"
    class="w-40 h-40 mx-auto mt-13"
  />
  <component
    :is="componentToRender"
    v-model="email"
    @change-type="componentType = $event"
  />
</template>

<script setup lang="ts">
import { definePageMeta, ref, computed, markRaw } from '#imports';
import { SignIn } from '~/pages/auth/login/_components/sign-in';
import { EnterPassword } from '~/pages/auth/login/_components/enter-password';
import { RequestInvite } from '~/pages/auth/login/_components/request-invite';
import {
  ResendConfirmInvite,
  ResendWelcomeInvite,
} from '~/pages/auth/login/_components/resend-invite';
import { SelfRegisterType } from '~/api/auth';
import exfluentialLogoSrc from '~/assets/images/exfluential-logo.svg?url';
import { authForbidden } from '~/middleware/auth-forbidden';
import { RegisterYourself } from '~/pages/auth/login/_components/register-yourself';
import { useOrganizationStore } from '~/store/organization';

definePageMeta({
  layout: 'centered',
  middleware: authForbidden,
});

const { organization } = storeToRefs(useOrganizationStore());

const email = ref('');
const componentType = ref(SelfRegisterType.SIGN_IN);

const ComponentTypeMap: Record<SelfRegisterType, unknown> = {
  [SelfRegisterType.SIGN_IN]: markRaw(SignIn),
  [SelfRegisterType.ENTER_PASSWORD]: markRaw(EnterPassword),
  [SelfRegisterType.REQUEST_INVITE]: markRaw(RequestInvite),
  [SelfRegisterType.RESEND_WELCOME_INVITE]: markRaw(ResendWelcomeInvite),
  [SelfRegisterType.RESEND_CONFIRMATION_INVITE]: markRaw(ResendConfirmInvite),
  [SelfRegisterType.REGISTER_YOURSELF]: markRaw(RegisterYourself),
};

const componentToRender = computed(() => {
  return ComponentTypeMap[componentType.value];
});
</script>
